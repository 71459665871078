/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.line {
  fill: none;
  stroke: #54e9fc;
  stroke-width: 4;
}

.previousLine {
  fill: none;
  stroke: #e2e4e7;
  stroke-width: 4;
  /* stroke-dasharray: 6, 4; */
}

.overlay {
  fill: none;
  pointer-events: all;
}

.copyText {
  fill: #647f99;
}
.copy {
  color: #647f99;
  font-family: Sen, sans-serif, Arial;
  font-weight: 400;
  font-size: 11px;
}

.copy text {
  font-family: Sen, sans-serif, Arial;
  font-weight: 400;
  font-size: 11px;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: transparent;
  stroke: transparent;
}
.x.axis .tick line {
  stroke: transparent;
}

.x.axis .domain {
  stroke: transparent;
}

.y.axis .tick line {
  stroke: #e2e4e7;
  transform: translateX(-60px);
}

.y.axis .tick text {
  transform: translateY(-10px) translateX(-10px);
}

.y.axis .domain {
  stroke: transparent;
}

.previousDot {
  fill: transparent;
  stroke: transparent;
}

.focus circle {
  fill: none;
  stroke: steelblue;
}
.tooltipdotprev {
  fill: rgb(148, 21, 21);
  stroke: rgb(148, 21, 21);
}

.bars {
  fill: #54e9fc;
  pointer-events: all;
}
.prevbars {
  fill: #d9e2ec;
  pointer-events: all;
}

div.tooltip {
  position: absolute;
  text-align: left;
  width: auto;
  height: auto;
  /* padding: 2px; */
  font-family: Sen, sans-serif, Arial;
  font-weight: 400;
  font-size: 11px;
  background: #ffffff;
  border-radius: 8px;
  pointer-events: none;
  color: #334e68;
  min-width: 133px;
  font-size: 13px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.2);
}
div.tooltip .label {
  background: #d9e2ec;
  padding: 4px 8px;
  width: 100%;
}
div.tooltip .count {
  padding: 4px 8px;
}
div.tooltip .prevcount {
  padding: 4px 8px;
  border-top: 1px solid #d9e2eb;
}
div.tooltip .current-circle {
  color: #54e9fc;
  margin-right: 10px;
}

div.tooltip .previous-circle {
  color: #d9e2ec;
  margin-right: 10px;
}
div.tooltip .tooltip-row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
div.tooltip .tooltip-row-line {
  width: 100%;
  flex-basis: 1;
  text-align: left;
}
